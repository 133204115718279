<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="handleCancel"
    okText="Valider"
    @ok="handleOk"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
      :layout="formLayout"
      id="demande_decision_form"
      name="demande_decision_form"
      class="adherent_form"
      :form="form"
    >
      <a-row>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Décision">
            <a-select
              placeholder="Décision"
              @change="handleDecisionChange"
              v-decorator="[
                'etat',
                {
                  rules: [
                    { required: true, message: 'Décision  est obligatoire!' },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="etatDemande in etatDemandes"
                :key="etatDemande.key"
              >
                {{ etatDemande.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="18">
          <a-form-item
            label="Responsable  :"
            v-bind="formItemLayout"
            v-if="showResponsableGroupeList"
          >
            <a-select
              style="width: 100%"
              placeholder="Destinataires"
              v-decorator="[
                'user_id',
                {
                  rules: [
                    {
                      required: showResponsableGroupeList,
                      message: 'Responsable de group est obligatoire!',
                    },
                  ],
                },
              ]"
            >
              <a-select-option :key="user.id" v-for="user in users">
                {{ user.full_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Dt. Décision">
            <a-date-picker
              style="width: 100%"
              :format="dateFormat"
              v-decorator="[
                'dt_comission',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Date décision est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
          <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Président">
            <a-input
             placeholder=""
              v-decorator="[
                'president_comission',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Président est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
         <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Référence">
            <a-input
             placeholder="0317/SORCDT/CEPQ/2017"
              v-decorator="[
                'ref',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Référence est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Justification">
            <a-textarea
              v-decorator="[
                'justification',
                {
                  rules: [
                    {
                      required: isDemandeRefused,
                      message: 'Justification est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template slot="footer">
      <a-button
        key="submit"
        type="primary"
        :loading="processing"
        @click="handleOk"
        >Valider</a-button
      >
    </template>
  </a-modal>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "DemandeDecisionForm",
  mounted() {
    this.fillTheForm();
  },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      },
      form: this.$form.createForm(this),
      decision: null,
    };
  },
  props: {
    processing: { type: Boolean, default: () => false },
    visible: { type: Boolean, default: () => false },
    demande: { type: Object },
  },
  computed: {
    ...mapState({
      etatsAdherents: (state) => state.adherents.etatsAdherents,
      etatDemandes: (state) => state.demandes.etats,
    }),
    showResponsableGroupeList() {
      return this.isDemandeOuvertureCabinetGroupe(
        this.get(this, "demande.type")
      );
    },
    title() {
      return `${this.getNomPrenom()} (${this.getTypeDemandeName()})`;
    },
    users() {
      return this.demande.members;
    },
    isDemandeRefused() {
      return this.isEtatDemandeOuvertureRefuser(this.decision);
    },
  },
  methods: {
    getNomPrenom() {
      return this.get(this.demande, "user.full_name");
    },
    getTypeDemandeName() {
      return this.get(this.demande, "type.nom_fr");
    },
    handleClose() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("create");
    },

    fillTheForm() {
      if (this.demande) {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            dt_comission: this.momentDate(this.demande.dt_comission),
            etat: this.demande.etat,
            president_comission: this.demande.president_comission,
            ref: this.demande.ref,
            justification: this.demande.justification,
          });

          if (this.showResponsableGroupeList)
            this.form.setFieldsValue({user_id: this.demande.user_id});

          this.decision = this.demande.etat;
        });
      }
    },
    handleDecisionChange(value) {
      this.decision = value;
      //Forcing revalidation  in case field changed from mandatory to not
      this.$nextTick(() => {
        this.form.validateFields(["justification"], {
          force: true,
        });
      });
    },
  },
};
</script>
