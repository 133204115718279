var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        {
          staticClass: "margin_bottom",
          attrs: { type: "flex", justify: "center" }
        },
        [
          _c(
            "a-col",
            { attrs: { span: 18 } },
            [_c("UserInfo", { attrs: { user: _vm.user } })],
            1
          )
        ],
        1
      ),
      _c("DemandesOuverturesEditor", {
        key: _vm.newDemandeModalKey,
        ref: "DemandeForm",
        attrs: {
          demande: _vm.selectedDemande,
          visible: _vm.visible,
          processing: _vm.loading,
          users: _vm.users,
          "medecin-list": _vm.medecinList,
          "current-user": _vm.user
        },
        on: {
          cancel: _vm.closeModal,
          close: _vm.closeModal,
          create: _vm.handleDemandeCreation,
          remove_medecin: _vm.handleRemoveMedecin
        }
      }),
      _c("DemandeDecisionForm", {
        key: _vm.newDemandeDecisionModalKey,
        ref: "DemandeDecisionForm",
        attrs: {
          demande: _vm.selectedDemande,
          visible: _vm.demandeDecisionFormvisible,
          processing: _vm.decisionFormProcessing
        },
        on: {
          cancel: _vm.closeDecisionFormModal,
          close: _vm.closeDecisionFormModal,
          create: _vm.handleDecisionChange
        }
      }),
      _c("h1", [_vm._v("Demandes ouvertures")]),
      _c(
        "a-row",
        {
          staticClass: "table_head",
          attrs: { type: "flex", justify: "space-between" }
        },
        [
          _c(
            "a-col",
            { staticClass: "mrgin_btm", attrs: { xs: 24, sm: 4 } },
            [
              _c(
                "a-button",
                {
                  staticClass: "editable-add-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.showModal }
                },
                [_vm._v("\n        Nouvelle\n      ")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mrgin_btm", attrs: { xs: 24, sm: 4 } },
            [
              _c("search-component", {
                on: {
                  change: function($event) {
                    return _vm.search($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("ListDemandesOuvertures", {
                attrs: {
                  "data-source": _vm.demandes,
                  "is-admin": true,
                  processing: _vm.processing
                },
                on: {
                  edit: function($event) {
                    return _vm.onEdit($event)
                  },
                  visite: function($event) {
                    return _vm.goToVisite($event)
                  },
                  delete: function($event) {
                    return _vm.onDelete($event)
                  },
                  decicion_change: function($event) {
                    return _vm.onDecicionChange($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "Votre dossier a été soumis", centered: "" },
          on: {
            ok: function() {
              return (_vm.successMessageModalVisible = false)
            }
          },
          model: {
            value: _vm.successMessageModalVisible,
            callback: function($$v) {
              _vm.successMessageModalVisible = $$v
            },
            expression: "successMessageModalVisible"
          }
        },
        [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.successMessageBody) }
          })
        ]
      ),
      _c("necessary-paiement-message", {
        attrs: { visible: _vm.paiementErrorMessageModalVisible },
        on: { close: _vm.closePaiementErrorModal }
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: "Décision changée",
            centered: "",
            "ok-text": "Ouvrir (nouvel onglet)",
            "cancel-text": "Non"
          },
          on: {
            ok: _vm.redirectToEtatActivites,
            cancel: _vm.close,
            close: _vm.close
          },
          model: {
            value: _vm.showRedirectionMessage,
            callback: function($$v) {
              _vm.showRedirectionMessage = $$v
            },
            expression: "showRedirectionMessage"
          }
        },
        [
          _vm._v(
            "\n        Après un changement de décision, vous êtes priés de vérifier que les états d'activités sont corrects.\n    "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }