<template>
  <div>
    <a-row type="flex" justify="center" class="margin_bottom">
      <a-col :span="18">
        <UserInfo :user="user" />
      </a-col>
    </a-row>
    <DemandesOuverturesEditor
      ref="DemandeForm"
      :key="newDemandeModalKey"
      :demande="selectedDemande"
      :visible="visible"
      :processing="loading"
      :users="users"
      :medecin-list="medecinList"
      :current-user="user"
      @cancel="closeModal"
      @close="closeModal"
      @create="handleDemandeCreation"
      @remove_medecin="handleRemoveMedecin"
    />
    <DemandeDecisionForm
      ref="DemandeDecisionForm"
      :key="newDemandeDecisionModalKey"
      :demande="selectedDemande"
      :visible="demandeDecisionFormvisible"
      :processing="decisionFormProcessing"
      @cancel="closeDecisionFormModal"
      @close="closeDecisionFormModal"
      @create="handleDecisionChange"
    />
    <h1>Demandes ouvertures</h1>
    <a-row type="flex" justify="space-between" class="table_head">
      <a-col :xs="24" :sm="4" class="mrgin_btm">
        <a-button class="editable-add-btn" type="primary" @click="showModal">
          Nouvelle
        </a-button>
      </a-col>
      <a-col :xs="24" :sm="4" class="mrgin_btm">
        <search-component @change="search($event)" />
      </a-col>
    </a-row>

    <a-row type="flex" justify="center">
      <a-col :span="24">
        <ListDemandesOuvertures
          :data-source="demandes"
          :is-admin="true"
          :processing="processing"
          @edit="onEdit($event)"
          @visite="goToVisite($event)"
          @delete="onDelete($event)"
          @decicion_change="onDecicionChange($event)"
        />
      </a-col>
    </a-row>
     <a-modal
        v-model="successMessageModalVisible"
        title="Votre dossier a été soumis"
        centered
        @ok="() => (successMessageModalVisible = false)"
      >
        <span v-html="successMessageBody"></span>
      </a-modal>
      <necessary-paiement-message :visible="paiementErrorMessageModalVisible" 
        @close="closePaiementErrorModal"/>
      <a-modal v-model="showRedirectionMessage" :title="'Décision changée'" centered 
        @ok="redirectToEtatActivites" 
        @cancel="close" 
        @close="close"
        :ok-text="'Ouvrir (nouvel onglet)'"
        :cancel-text="'Non'">
          Après un changement de décision, vous êtes priés de vérifier que les états d'activités sont corrects.
      </a-modal>
  </div>
</template>
<script>
import ListDemandesOuvertures from "@/components/common/ListDemandesOuvertures";
import DemandesOuverturesEditor from "@/components/common/DemandesOuverturesEditor";
import SearchComponent from "@/components/common/Search";
import UserInfo from "@/components/common/UserInfo";
import DemandeDecisionForm from "./DemandeDecisionForm";
// import ModalMessage from "@/components/common/ModalMessage";
import NecessaryPaiementMessage from "@/components/common/NecessaryPaiementMessage";


import {mapActions, mapGetters, mapState} from "vuex";
import _ from "lodash";


export default {
  Name: "DemandesIndex",
  components: {
    ListDemandesOuvertures,
    DemandesOuverturesEditor,
    UserInfo,
    DemandeDecisionForm,
    SearchComponent,
    // ModalMessage,
    NecessaryPaiementMessage
  },
  created() {
    this.id = this.$route.params["id"];
    this.getUser(this.id);
    this.fetchData();
    this.getAdditionalData();
  },
  data() {
    this.search = _.debounce(this.search, 750);
    return {
      loading: false,
      visible: false,
      demandeDecisionFormvisible: false,
      processing: true,
      successMessageModalVisible: false,
      paiementErrorMessageModalVisible: false,
      successMessageBody: "",
      decisionFormProcessing: false,
      selectedDemande: null,
      newDemandeModalKey: -1,
      newDemandeDecisionModalKey: -2,
      medecinList : [],
      showRedirectionMessage: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.adherents.selected_adherent,
      demandes: (state) => state.demandes.demandes,
      users: (state) => state.messageries.paid_users_emails,
    }),
    ...mapGetters({ demandeOuvertureSuccessMessage : "getDemandeOuvertureSuccessMessage" ,
                    demandeTransfertSuccessMessage: "getDemandeTransfertSuccessMessage" ,
                    compteBancaire : "getOrganisationCompteBancaire",
                    ccp : "getOrganisationCCP"
      }),
  },
  methods: {
    getAdditionalData() {
      this.$store.cache.dispatch("fetchPaiedUsersEmails");
      this.$store.cache.dispatch("fetchMessageCommunication");
      this.$store.cache.dispatch("fetchOrganisation");
    },
    closeModal() {
      this.selectedDemande = null
      this.stopLoading()
      this.visible = false
    },
    closePaiementErrorModal(){
      this.paiementErrorMessageModalVisible = false;
    },
    showPaiementErrorModal(){
      this.paiementErrorMessageModalVisible = true;
    },
    showModal() {
      if (!this.isUserPaiementSettled(this.user)) {
        this.displayPaiementMandatoryError();
        return;
      }
      this.genererateNewDemandeModalKey();
      this.visible = true;
    },
    closeDecisionFormModal() {
      this.selectedDemande = null
      this.demandeDecisionFormvisible = false
    },
    showDecisionFormModal() {
      this.genererateNewDemandeDecisionModalKey()
      this.demandeDecisionFormvisible = true
    },
    genererateNewDemandeModalKey() {
      this.newDemandeModalKey = this.getRandomInt()
    },
    genererateNewDemandeDecisionModalKey() {
      this.newDemandeDecisionModalKey = this.getRandomInt()
    },
    displayPaiementMandatoryError() {
       this.showPaiementErrorModal()
    },
    onDelete(id) {
      this.delete(id).then(() => this.fetchData())
    },
    goToVisite(id) {
      this.$router.push({ name: "user_visites", params: { id } });
    },
    onEdit(id) {
      this.edit(id).then((response) => {
        this.selectedDemande = response.data
        this.medecinList = this.get(response, 'data.medecins')
        this.showModal();
      });
    },
    onDecicionChange(id) {
      this.startProcessing()
      this.edit(id).then((response) => {
        this.selectedDemande = response.data
        this.showDecisionFormModal()
      }).finally(() => this.stopProcessing())
    },
    handleDemandeCreation() {
      const form = this.$refs.DemandeForm.form;
      let that = this;
      form.validateFields((err, values) => {
        if (!err) {
          values["dt_demande"] = this.formatDate(
            values["dt_demande"],
            "YYYY/MM/DD"
          );
          this.startLoading();
          values.medecin_list = this.medecinList
          if (this.selectedDemande) {
            this.update({ id: this.selectedDemande.id, ...values })
              .then((response) => {
                if (response.status === that.HTTP_OK) {
                  that.closeModal();
                  form.resetFields();
                  that.fetchData();
                }
              })
              .finally(() => that.stopLoading());
            return;
          }
          this.add({ user_id: this.id, ...values })
            .then((response) => {
              if (response.status === that.HTTP_OK) {
                that.closeModal();
                form.resetFields();
                this.displayDemandeOuvertureSuccessMessage(response.data.type.code);
                that.fetchData();
              }
            })
            .finally(() => that.stopLoading());
        }
      });
    },
    displayDemandeOuvertureSuccessMessage(typeDemande) {
      let successMessages = {
        OC : this.demandeOuvertureSuccessMessage,
        CG : this.demandeOuvertureSuccessMessage,
        TRS : this.demandeTransfertSuccessMessage
      }
      this.successMessageBody = this.get(successMessages, typeDemande);
      this.successMessageModalVisible = true;
    },
    handleRemoveMedecin(key){
      const dataSource = [...this.medecinList]
      this.medecinList = dataSource.filter(item => item.id !== key)
    },
    handleDecisionChange() {
      const form = this.$refs.DemandeDecisionForm.form;
      let that = this;
      form.validateFields((err, values) => {
        if (!err) {
          this.startDecisionFormLoading();
          if (this.selectedDemande) {
            values["dt_comission"] = this.formatDate(
              values["dt_comission"],
              "YYYY-MM-DD"
            );
            this.updateEtat({ id: this.selectedDemande.id, ...values })
              .then(() => {
                if (this.$refs.DemandeDecisionForm.form.getFieldValue('etat') != this.selectedDemande.etat)
                  this.showRedirectionMessage = true;
                that.closeDecisionFormModal();
                form.resetFields();
                that.fetchData();
              })
              .finally(() => that.stopDecisionFormLoading());
          }
        }
      });
    },
    fetchData(q = "") {
      this.startProcessing();
      this.getDemandes({ q: q, id: this.id }).finally(() =>
        this.stopProcessing()
      );
    },
    startProcessing() {
      this.processing = true;
    },
    search(value) {
      this.fetchData(value);
    },
    stopProcessing() {
      this.processing = false
    },
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
    },
    stopDecisionFormLoading() {
      this.decisionFormProcessing = false;
    },
    startDecisionFormLoading() {
      this.decisionFormProcessing = true;
    },
    ...mapActions({
      getUser: "fetchAdminAdherentsById",
      fetchNewDemandes: "fetchNewDemandeOuverturesCount",
      getDemandes: "fetchDemandes",
      add: "storeDemandes",
      edit: "fetchDemandeById",
      delete: "destroyDemande",
      update: "updateDemande",
      updateEtat: "updateEtatDemande",
    }),
    close(){
      this.showRedirectionMessage = false;
    },
    redirectToEtatActivites(){
      const route = this.$router.resolve({
        name: 'user_etat_activite', 
        params: { id: this.id },
      });

      window.open(route.href, '_blank');
      this.close();
    },
  },
};
</script>

<style scoped>
.mrgin_btm {
  margin-bottom: 3%;
}
</style>
